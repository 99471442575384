import React, { useEffect, useState } from "react";
import SisterhoodSVG from "../assets/img/sisterhood.svg";
import q1 from "../assets/img/sisterhood1.svg";
import q2 from "../assets/img/sisterhood2.svg";

// const percentageStyle = {
//   fontWeight: "700",
// };

const SisterhoodSection = () => {
  useEffect(() => {
    document.documentElement.style.overflowX = "hidden";
    document.body.style.overflowX = "hidden";
    return () => {
      document.documentElement.style.overflowX = "auto";
      document.body.style.overflowX = "auto";
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [sisterhoodHeight, setSisterhoodHeight] = useState(0);
  const [sisterhoodTextHeight, setSisterhoodTextHeight] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);

  const bigStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textAlign: "center",
    lineHeight: "78%",
    fontFamily: "Candal",
    fontSize: isMobile ? "100px" : "120px",
    fontStyle: "normal",
    // fontWeight: "715",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    // letterSpacing: isMobile ? "0px" : "-1px",
    textTransform: "uppercase",
  };

  const smallStyle = {
    color: "var(--BRAND-COLOURS-ONYX, #070706)",
    textAlign: "center",
    lineHeight: "100%",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "18px" : "24px",
    fontStyle: "normal",
    fontWeight: "400",
    // letterSpacing: "-0.24px",
  };

  const updateDimensions = () => {
    const sisterhoodSection = document.getElementById("upspoken");
    const textSection = document.getElementById("textSisterhood");
    const svgSection = document.getElementById("svgSection");

    if (sisterhoodSection) {
      const height = sisterhoodSection.offsetHeight;
      console.log("sisterhoodSection.offsetHeight", height);
      setSisterhoodHeight(height);
    }
    if (textSection) {
      const height = textSection.offsetHeight;
      console.log("textSection.offsetHeight", height);
      setSisterhoodTextHeight(height);
    }
    if (svgSection) {
      const height = svgSection.offsetHeight;
      console.log("svgSection.offsetHeight", height);
      setSvgHeight(height);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
      updateDimensions();
    };
    handleResize(); // Calculate dimensions initially

    window.addEventListener("resize", handleResize);
    const svgImage = document.getElementById("svgSection");
    svgImage.addEventListener("load", updateDimensions); // Update dimensions when the image loads

    return () => {
      window.removeEventListener("resize", handleResize);
      svgImage.removeEventListener("load", updateDimensions);
    };
  }, []);

  return (
    <div className="relative bg-[#C10FF]">
      <div
        id="sisterhood-section"
        className={`bg-[#C140FF] justify-center items-center ${
          isMobile ? "px-3" : "px-16"
        } pt-10 pb-10`}
        style={{
          height: isMobile
            ? sisterhoodTextHeight + sisterhoodHeight + svgHeight
            : sisterhoodTextHeight + sisterhoodHeight + svgHeight - 100,
        }}
      >
        <div
          id="textSisterhood"
          className="text-orange mb-2"
          style={smallStyle}
        >
          {isMobile && (
            <img
              src={q1}
              alt="q1"
              className="z-10 relative mx-auto w-full px-3 mb-3"
            />
          )}
          {isMobile && (
            <img
              src={q2}
              alt="q2"
              className="z-10 relative mx-auto w-full px-3 my-3"
            />
          )}
          To avoid travelling alone...
          <br />
          Women change their travel plans on <b>50%</b> of their nights out.
          <br />
          Given the option, <b>49%</b> of women would rather take a taxi with a
          woman they do not know.
          <br />
          <br />
          We’ve discovered an...
          <br />
        </div>
        <img
          id="svgSection"
          src={SisterhoodSVG}
          alt="Sisterhood"
          className="absolute left-1/2 transform -translate-x-1/2 z-5"
          style={{
            width: "120vw",
            maxWidth: "200%",
            marginTop: `${sisterhoodHeight}px`,
          }}
        />
        <div className="flex flex-row jsutify-start">
          {!isMobile && (
            <img src={q1} alt="q1" className="z-10 self-start mt-10 relative" />
          )}
          <p id="upspoken" style={bigStyle} className="z-10 relative">
            UNSPOKEN SISTERHOOD
          </p>
          {!isMobile && (
            <img
              src={q2}
              alt="q2"
              className="z-10  self-start pt-10 relative"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SisterhoodSection;
