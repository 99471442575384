import React, { useEffect, useState } from "react";
import lights from "../assets/img/lights.png";
import sharingLoc from "../assets/img/sharinglocation.png";
import walk from "../assets/img/walking.png";

const strats = [
  {
    img: lights,
    text: "STICKING TO CROWDED,  WELL-LIT STREETS",
  },
  {
    img: sharingLoc,
    text: "SHARING LOCATION WITH FRIENDS AND FAMILY",
  },
  {
    img: walk,
    text: "AVOIDING WALKING ALONE AT NIGHT",
  },
];

const StratsSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bigStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textAlign: "center",
    lineHeight: "78%" /* 49.92px */,
    fontFamily: "Candal",
    fontSize: isMobile ? "34px" : "64px",
    fontStyle: "normal",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    // letterSpacing: "0.8px",
    // fontWeight: "715",
    textTransform: "uppercase",
  };

  const smallStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textAlign: "center",
    lineHeight: "100%" /* 40px */,
    fontFamily: "KHTeka",
    fontSize: isMobile ? "18px" : "40px",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0.4px",
  };

  return (
    <div
      className={`bg-black justify-center items-center py-5 ${
        isMobile ? "px-3" : "px-16"
      }`}
    >
      <p className="text-orange mb-5" style={smallStyle}>
        This is why women do not rely on current safety apps. Instead, women
        resort to self-imposed rules, such as:
      </p>

      <div
        className={`${
          isMobile ? "flex-col px-3" : "justify-between"
        } justify-center items-center`}
        style={{ display: "flex" }}
      >
        {strats.map((stat, index) => (
          <div
            key={index}
            className="flex-col p-3 "
            style={{ display: "flex" }}
          >
            <img
              src={stat.img}
              alt="strategy"
              className="h-[150px] object-contain mb-10"
            />
            <p style={bigStyle} className="mb-10">
              {stat.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StratsSection;
