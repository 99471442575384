import React, { useEffect, useState } from "react";

// PlusIcon.jsx

const PlusIcon = ({ color }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM15 7.875C15 7.32272 14.5523 6.875 14 6.875C13.4477 6.875 13 7.32272 13 7.875V13H7.875C7.32272 13 6.875 13.4477 6.875 14C6.875 14.5523 7.32272 15 7.875 15H13V20.125C13 20.6773 13.4477 21.125 14 21.125C14.5523 21.125 15 20.6773 15 20.125V15H20.125C20.6773 15 21.125 14.5523 21.125 14C21.125 13.4477 20.6773 13 20.125 13H15V7.875Z"
      fill={color ? color : "#F4F1E9"}
    />
  </svg>
);

const FAQSubSection = ({ title, qna, primary, secondary, style }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(null); // Track the active question

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the active question
  };

  const titleStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textAlign: "center",
    fontFamily: "Candal",
    fontSize: isMobile ? "48px" : "150px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    // fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%",
    // letterSpacing: "0px",
    textTransform: "uppercase",
  };

  const questionStyle = {
    color: "var(--white, #FFF)",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "18px" : "32px",
    // fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "100%",
    letterSpacing: "-0.32px",
    textTransform: "uppercase",
    cursor: "pointer", // Make it obvious it's clickable
    marginBottom: "1rem",
  };

  const answerStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "16px" : "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100%",
    letterSpacing: "-0.24px",
    display: "none", // Initially hide the answer
  };

  const separatorStyle = {
    borderBottom: "1px solid var(--white, #FFF)", // Style the separator line
    marginBottom: "1rem", // Adjust the spacing between the questions
  };

  return (
    <div
      className={`${title === undefined ? "" : "py-20"}  max-w-1/2 mx-auto ${
        isMobile ? "px-3" : "px-32"
      }`}
      style={{
        backgroundColor: primary,
      }}
    >
      <h2 style={titleStyle} className="mb-5">
        {title}
      </h2>
      {qna.map((item, index) => (
        <div
          key={index}
          onClick={() => toggleFAQ(index)}
          style={{
            cursor: "pointer", // Make it obvious it's clickable
          }}
        >
          <div className="flex flex-row my-2">
            <div className="mx-4 mt-1">
              <PlusIcon color={secondary} className="" />
            </div>
            <div>
              <h3 style={questionStyle}>{item.q}</h3>
              <div
                style={{
                  ...answerStyle,
                  display: activeIndex === index ? "block" : "none", // Display if active
                }}
              >
                {item.a}
              </div>
            </div>
          </div>
          <div style={separatorStyle}></div> {/* Separator line */}
        </div>
      ))}
    </div>
  );
};

export default FAQSubSection;
