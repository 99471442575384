import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import q1 from "../assets/img/quote1.webp";
import q2 from "../assets/img/quote2.webp";
import q3 from "../assets/img/quote3.webp";
import q4 from "../assets/img/quote4.webp";
import q5 from "../assets/img/quote5.webp";
import q6 from "../assets/img/quote6.webp";

// import arrows from "../assets/img/arrows.svg";
import arrowLeft from "../assets/img/arrowleft.svg";
import arrowRight from "../assets/img/arrowright.svg";

const quotes = [
  {
    quote:
      "I have spent the last 2 years of uni struggling to make sure I don't get back home alone after a night out so I am super excited to be able to use this and have more control over what time I want to get back home",
    name: "LISA",
    // occupation: "Biomed Student",
    image: q1,
    bg: "#03AF70",
  },
  {
    quote:
      "The idea of sharing a ride with another woman sounds reassuring to me and I know that i'd feel more comfortable throughout my way home",
    name: "CHIARA",
    occupation: "Bartender",
    image: q2,
    bg: "#F48F14",
  },
  {
    quote: "Honestly amazing! I'll be using this once the app is on the market",
    name: "TIKTOK USER",
    // occupation: "Engineering Student",
    image: q3,
    bg: "#1F5EFF",
  },
  {
    quote:
      "I hate having to wait until my friends want to leave a venue to take a ride together so i'm looking forward to this!",
    name: "KATHERINE",
    // occupation: "Biomed Student",
    image: q4,
    bg: "#03AF70",
  },
  {
    quote:
      " I can't wait to try the app and also have the possibility of making new friends whilst getting home safely at a cheap price",
    name: "SOPHIA",
    // occupation: "Bartender",
    image: q5,
    bg: "#F48F14",
  },
  {
    quote: "Definetely using this!",
    name: "LUISA",
    // occupation: "Engineering Student",
    image: q6,
    bg: "#1F5EFF",
  },
];

const Testimonials = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.style.overflowX = "hidden";
    document.body.style.overflowX = "hidden";
    return () => {
      document.documentElement.style.overflowX = "auto";
      document.body.style.overflowX = "auto";
    };
  }, []);

  const quoteStyle = {
    color: "#000",
    textAlign: "center",
    fontFamily: '"Candal"',
    fontSize: isMobile ? "27px" : "40px",
    fontStyle: "normal",
    // fontWeight: "715",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    lineHeight: "78%", // 80%
    // letterSpacing: "0.64px",
    textTransform: "uppercase",
    margin: "0",
  };

  const nameStyle = {
    color: "#000",
    fontFamily: '"KHTeka"',
    fontSize: isMobile ? "16px" : "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "1", // 100%
    letterSpacing: "-0.32px",
    margin: "0",
  };

  // const occupationStyle = {
  //   color: "#000",
  //   fontFamily: '"KHTeka"',
  //   fontSize: isMobile ? "16px" : "32px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: "1", // 100%
  //   letterSpacing: "-0.32px",
  //   margin: "0",
  // };

  return (
    <div className="bg-black h-auto relative">
      <div
        className={`flex flex-row absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full z-10 justify-between ${
          isMobile ? "px-0" : "px-10"
        }`}
      >
        <img src={arrowLeft} alt="arrowleft" />
        <img src={arrowRight} alt="arrowleft" />
      </div>
      {/* <img
        src={arrows}
        alt="arrows"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full z-10"
        // style={{ maxWidth: "calc(100% - 8rem)" }} // Adjust padding
      /> */}
      <Slider
        dots={false}
        infinite={true}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={false} // Turn off autoplay
        speed={isMobile ? 1 : 1} // Set speed to 0 for no animation
        className="pb-10 flex"
        style={{ display: "flex" }}
        nextArrow={null}
        prevArrow={null}
      >
        {quotes.map((testimonial, index) => (
          <div>
            <div
              className={`test-head flex ${
                isMobile ? " flex-col" : "flex-row"
              }`}
              style={{ width: "100vw", height: "100vh", display: "flex" }}
            >
              {/* <div className="testimonial flex flex-row">
                <p style={quoteStyle}>“</p> */}
              <div
                className={`testimonial flex justify-center p-3 items-center`}
                key={testimonial.name}
                style={{
                  height: isMobile ? "50%" : "100vh",
                  width: isMobile ? "100%" : "50vw",
                  backgroundColor: testimonial.bg,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="flex flex-row justify-between">
                  <p style={quoteStyle}>“</p>
                  <div className="flex flex-col items-center mx-auto">
                    <p style={quoteStyle}>{testimonial.quote}</p>
                    <p style={nameStyle} className="mt-5">
                      {testimonial.name}
                    </p>
                  </div>
                  <p style={quoteStyle}>”</p>
                </div>
                {/* <p style={occupationStyle}>{testimonial.occupation}</p> */}
                {/* </div>
                <p style={quoteStyle}>”</p> */}
              </div>
              <div
                className={`img-half flex`}
                style={{
                  height: isMobile ? "50%" : "100vh",
                  width: isMobile ? "100%" : "50vw",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
