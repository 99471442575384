import React, { useState, useEffect } from "react";
import Petals from "../assets/img/petals.png";
// import Prb from "../assets/img/problems_w_safety.svg";
import Research from "../assets/img/research.webp";
import ResearchMobile from "../assets/img/researchMob.webp";
import Matrix from "../assets/img/matrix_options.svg";
import MatrixMobile from "../assets/img/matrix_mobile.svg";

const TextSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const whiteStyle = {
    color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
    textAlign: "center",
    textRendering: "optimizeLegibility", // You may mean 'text-edge: cap;', but 'textRendering' is not a valid CSS property
    fontFamily: "Candal",
    fontSize: "60px",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%",
    // letterSpacing: "-0.6px",
  };

  const orangeStyle = {
    color: "var(--BRAND-COLOURS-YELLOW, #FEDB01)",
    textAlign: "center",
    textRendering: "optimizeLegibility", // You may mean 'text-edge: cap;', but 'textRendering' is not a valid CSS property
    fontFamily: "KHTeka",
    fontSize: "35px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "100%",
    letterSpacing: "-0.35px",
    textTransform: "uppercase",
  };

  // const smallStyle = {
  //   color: "var(--BRAND-COLOURS-OFFWHITE, #F4F1E9)",
  //   leadingTrim: "both",
  //   textEdge: "cap",
  //   fontFamily: "KHTeka",
  //   fontSize: isMobile ? "18px" : "24px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: "100%" /* 24px */,
  //   letterSpacing: "-0.24px",
  // };

  return (
    <div
      className={`bg-black justify-center items-center py-20 ${
        isMobile ? "px-3" : "px-32"
      } `}
    >
      <p style={whiteStyle}>PROBLEM</p>
      <p className={`text-orange mb-5 `} style={orangeStyle}>
        Women feeling unsafe travelling alone at night
      </p>
      <p style={whiteStyle}>OUR SOLUTION</p>
      <p className={`text-orange mb-12 `} style={orangeStyle}>
        A platform that connects women, empowering them to travel together,
        safer and cheaper.
      </p>
      <img
        src={Petals}
        alt="Petals"
        className={`self-center mx-auto ${
          isMobile ? "h-36 mb-12" : "h-40 mb-20 mt-4 "
        } `}
      />
      <div
        className={
          isMobile
            ? "flex flex-col items-center "
            : "flex flex-row items-center"
        }
      >
        <img
          src={isMobile ? ResearchMobile : Research}
          alt="Research"
          className={isMobile ? "w-full px-3 pb-5" : "w-full px-16 pb-5"}
        />
      </div>
      <img
        src={!isMobile ? Matrix : MatrixMobile}
        alt="Problems"
        className="w-full"
      />
    </div>
  );
};

export default TextSection;
