import React, { useEffect, useState } from "react";

const ResearchToggleBig = ({
  color,
  name,
  content,
  imgs,
  isOpen = false,
  padding = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSection = () => {
    setIsExpanded(!isExpanded);
  };

  const titleStyle = {
    color: color,
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Candal",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontSize: "96px",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%" /* 94.08px */,
    // letterSpacing: "1.96px",
    textTransform: "uppercase",
  };

  const readmore = {
    color: color,
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Candal",
    fontVariationSettings: "'wght' 550, 'wdth' 0",
    fontSize: "50px",
    fontStyle: "normal",
    // fontWeight: "715",
    lineHeight: "78%" /* 94.08px */,
    // letterSpacing: "1.96px",
    textTransform: "uppercase",
  };

  return (
    <div
      className={`w-full pt-10 justify-center ${isMobile ? "px-3" : "px-16"} `}
      // style={{
      //   backgroundColor: color,
      // }}
    >
      <div
        className="px-6 py-4 cursor-pointer items-center"
        onClick={toggleSection}
      >
        <p className=" text-5xl font-bold" style={titleStyle}>
          {name}
        </p>
        {isExpanded && (
          <div
            className={`px-6 py-4 justify-center ${
              !padding ? "w-full" : "w-3/4"
            }`}
          >
            {content}
          </div>
        )}
        {/* <div className="flex flex-row justify-between">
        <img src={br11} alt="br11" />
        <img src={br12} alt="br12" /> 
        <img src={br13} alt="br13" />
        <img src={br14} alt="br14" />
      </div> */}
        {imgs}

        <p style={readmore} className="flex mt-8 mx-auto justify-end">
          {isExpanded ? "READ less ↑" : "READ MORE ↓"}
        </p>
      </div>
    </div>
  );
};

export default ResearchToggleBig;
